<template>
  <div class="wrap">
    <div class="tabs">
      <van-tabs v-model="active" style="height:100%">
        <van-tab name="approve" title="审批" v-if="type=='todo'">
          <keep-alive>
            <approve></approve>
          </keep-alive>
        </van-tab>
        <van-tab name="text" title="正文">
          <div v-if="wordList.length==0 && fileId==''" style="margin-top:18px;text-align:center;color:gray">
              暂无Word附件
          </div>
        </van-tab>
        <van-tab title="表单" name="formdata" style="width:100%;height:100%">
          <!-- 请求接口返回页面iframe嵌入 -->
          <iframe width="100%" height="100%" :src="xoaOrigin+ '/stk-oa/processPCViewer?taskId='+this.taskId+'&processInstId='+this.instanceId+'&tenantId='+this.tenantId" frameborder="0"></iframe>
        </van-tab>
        <van-tab title="附件" name="filelist">
          <attach-file-list :fileList="fileList"></attach-file-list>
        </van-tab>
        <van-tab title="跟踪" name="track">
          <track-list></track-list>
          <!-- <template #title>
            <img class="icon-img" src="@/assets/todo_icon_4.png" alt=""/>
          </template> -->
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import { Tab, Tabs } from "vant";
import Approve from "./approve.vue";
import TrackList from './trackList.vue';
import TextFile from './textFile.vue'
import AttachFileList from './attachFileList.vue'
import {getProcessInstData,getPrintConfig} from '@/api/index'

export default {
  name:'Main',
  components: {
    VanTab: Tab,
    VanTabs: Tabs,
    Approve,
    TrackList,
    TextFile,
    AttachFileList
  },
  watch:{
    active(val){
      if(val=='text' && this.fileId!=''){
        sessionStorage.setItem('active','filelist');
        this.$router.push('/textfile?fileId='+this.fileId+'&title='+this.fileName);
      }else if(val!='text'){
        sessionStorage.removeItem('active');
      }
    },
    '$route':{
      handler(val){
        if(val.path=='/main' && sessionStorage.getItem('active')){
          this.active = sessionStorage.getItem('active')
        }
      },
      deep:true
    }
  },
  computed:{
    type(){
      return this.$route.query.type
    },
    instanceId(){
      return this.$route.query.instanceId
    },
    taskId(){
      return this.$route.query.taskId
    },
    tenantId(){
      return this.$route.query.tenantId
    },
    modelKey(){
        return this.$route.query.modelKey
    },

    renderConfig() {
        return this.renderConfigJson || processLayoutConfig[this.processInstData.modelKey] || {}
    },
    processFormData() {
        let processFormData = (this.instData || {}).processFormData || {}
        processFormData.DOC_FILE = processFormData.DOC_FILE ? JSON.parse(processFormData.DOC_FILE) : []
        processFormData.DOC_APPENDIX = processFormData.DOC_APPENDIX ? JSON.parse(processFormData.DOC_APPENDIX) : []
        return processFormData
    },
  },
  data() {
    return {
      active: 0,
      xoaOrigin:  'http://xoatest.sihc.com.cn/' ,
      instData:'',
      renderConfigJson:'',
      fileList:[],
      wordList:[],
      textFileUrl:'',
      fileId:'',
      fileName:''
    };
  },
  mounted(){
    this.$store.dispatch('addCachedView','Main');
    this.getProcessInstData();
  },
  methods:{
    getProcessInstData() {
      getProcessInstData({processInstId: this.instanceId}).then((res)=>{
        if(res.code==200){
          this.instData = res.data;
          this.getPrintConfig();
        }
      })
    },
    async getPrintConfig() {
        const { data } = await getPrintConfig({
            modelKey: this.modelKey.replace(/_(\d+)$/, '')
        })
        this.renderConfigJson = JSON.parse(data.printConfig || '{}')[this.modelKey]
        this.$nextTick(() => {
            var self = this
            var fileList = []
            /* 附件 */
            var fileListBox = []
            /* 正文 */
            var wordList = [];
            if (self.renderConfig.keyMap && self.instData?.processFormData && self.processFormData[self.renderConfig.keyMap['正文WORD附件']]) {
                fileList = JSON.parse(self.processFormData[self.renderConfig.keyMap['正文WORD附件']] || '[]')
                wordList.push(...fileList)
            }
            if (self.renderConfig.keyMap && self.instData?.processFormData && self.renderConfig.keyMap['fileList']) {
            for (let index = 0; index < self.renderConfig.keyMap['fileList'].length; index++) {
                const element = self.renderConfig.keyMap['fileList'][index]
                if (element.isWord) {
                    fileList = JSON.parse(self.processFormData[element.key] || '[]')
                    wordList.push(...fileList)
                } else {
                    var list = JSON.parse(self.processFormData[element.key] || '[]')
                    fileListBox.push(...list)
                }
            }
            }
            if (self.renderConfig.keyMap && self.instData?.processFormData && self.processFormData[self.renderConfig.keyMap['附件']]) {
                var list = JSON.parse(self.processFormData[self.renderConfig.keyMap['附件']] || '[]')
                fileListBox.push(...list)
            }
            this.fileList = fileListBox || []
            this.wordList = wordList || []
            if(this.wordList.length>0){
              this.fileId = this.wordList[0].id;
              this.fileName = this.wordList[0].name;
              // 已办列表点进来页面，直接选中正文tab,如果有正文的话直接跳转路由
              if(this.type!='todo'){
                sessionStorage.setItem('active','filelist');
                this.$router.push('/textfile?fileId='+this.fileId+'&title='+this.fileName);
              }
            }
        })
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-tabs__line {
  width: 20%;
  background-color: #3a75c5;
}

.wrap {
  background: #f5f5f5;
  height: 100vh;
  overflow: scroll;
  .tabs {
    .icon-img {
      width: 0.14rem;
      height: 0.16rem;
    }
  }
  
}
</style>
