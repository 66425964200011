<template>
    <div class="container">
        <ul class="article-list">
            <li class="article" v-for="(item,index) in processCommentList" :key="index">
                <div class="content">
                    <div style="margin:2px 10px 10px">
                        <div class="flex-between">
                            <h3 class="van-ellipsis">{{item.nodeName}}</h3>
                            <a v-if="item.nodeName=='起草'" class="btn-tag">提交</a>
                        </div>
                        <p>起始时间：{{item.startTime || '--'}}</p>
                        <p>处理人：{{item.operateUserName}}</p>
                        <p>处理状态：{{transformlLabel(item)}}</p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { getReminderMsgList } from '@/api/index'
export default {
    data(){
        return{
            processCommentList:[],
            loadedAll:false,
            total:0,
        }
    },
    computed:{
        taskId() {
            return this.$route.query.taskId
        },
        instanceId() {
            return this.$route.query.instanceId
        }
    },
    mounted(){
        this.getProcessCommentList();
    },
    methods:{
        transformlLabel(item){
            let val = '';
            if(item.readTime){
                val = '已打开阅读过（' + item.readTime +')'
            }else if(!item.readTime && item.endTime){
                val = '已处理（' + item.endTime +')'
            }
            return val
        },
        // 查询审批记录列表
        getProcessCommentList() {
            getReminderMsgList({processInstId:this.instanceId}).then(res => {
                if (res.code == 200) {
                    this.processCommentList = res.data
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.btn-tag{
    background:#1989fa;
    height:19px;
    color: white;
    padding: 0px 7px;
    border-radius: 5px;
}
.flex-between{
    display:flex;
    justify-content: space-between;
}

p{
    margin-block-start: 0;
    margin-block-end: 0;
}
.container{
    width: 100%;
    height: 100vh;
    background: #f5f5f5;
    overflow: scroll;
  }
  .article-list {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 10px 10px 0;
    position: relative;
    top:0px;
    .article {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      box-sizing: border-box;
      background: #FFF;
      border-radius: 5px;
      box-shadow: 0 0 6px #e3e3e3;
      .content {
        width:100%;
        h3{
          margin: 0.10rem 0rem;
        }
        p {
          width: 100%;
          line-height: 23px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 0.15rem;
        }
      }
    }
  }
</style>