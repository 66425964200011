<template>
  <div class="wrap" style="background:#ffffff">
    <div class="main">
      <div class="todo-title">{{flowTitle}}</div>
      <van-col span="24">
        <van-field
          readonly
          clickable
          name="picker"
          :value="comment"
          input-align="right"
          label="常用审批意见"
          placeholder="请选择"
          class="comment-select"
          right-icon="van-icon van-icon-arrow van-cell__right-icon"
          @click="showPicker = true">
        </van-field>
        <van-cell class="van-cell--required"><span style="margin-left:5px;color: #3a75c5;">审批意见</span></van-cell>
        <van-field
          name="comment"
          v-model="remark"
          label=""
          rows="3"
          input-align="left"
          placeholder="请输入审批意见"
          type="textarea"
          class="comment-input"
          style="margin-top:-10px"
          >
        </van-field>
        <van-popup v-model="showPicker" position="bottom" :safe-area-inset-bottom="true">
          <van-picker
            show-toolbar
            :columns="commentList"
            value-key="name"
            @cancel="showPicker = false"
            @confirm="onConfirm"
          />
        </van-popup>
      </van-col>
      <van-cell class="van-cell--required"><span style="margin-left:5px;color: #3a75c5;">审批后即将流向</span></van-cell>
      <van-col span="24" style="padding:12px;background:white" v-if="!loading">
        <van-radio-group v-model="actionName" icon-size="16px">
          <van-radio v-for="(item,index) in actionMenuList" :key="index" :name="item.actionMenuName" style="padding-bottom:22px">
            {{item.actionMenuName}}
          </van-radio>
        </van-radio-group>
        <van-field
          v-show="actionmenuApprovaLink=='true' && linkUserList.length>0"
          readonly
          clickable
          name="picker"
          :value="userSelectedList.join(',')"
          input-align="right"
          :label="approvaLinkTitle"
          placeholder="请选择"
          class="comment-select"
          right-icon="van-icon van-icon-arrow van-cell__right-icon"
          @click="handleSelectUser">
        </van-field>
      </van-col>
      <div v-else class="flex-center">
        <van-loading size="24px">加载中...</van-loading>
      </div>
      <div class="fixed-bottom">
        <van-row type="flex" justify="center">
          <van-col style="width:100%">
            <van-button round type="info" class="vanButton" @click="submitApprove">审 批</van-button>
          </van-col>
        </van-row>
      </div> 
    </div>
  </div>
</template>
<script>
import {wordList,actionMenus,approveSubmit} from '@/api/index'
export default {
  data() {
    return {
      active: 2,
      actionName:'',
      remark:'',
      comment:'',
      commentList:[],
      actionMenuList:[],
      showPicker:false,
      approvaLinkTitle:'',
      approveLinkVar:'',
      actionmenuApprovaLink:false,
      approveLinkUserList:[],
      linkUserList:[],
      userSelectedList:[],
      loading:false,
    };
  },
  computed:{
    instanceId(){
      return this.$route.query.instanceId
    },
    taskId(){
      return this.$route.query.taskId
    },
    tenantId(){
      return this.$route.query.tenantId
    },
    modelKey(){
      return this.$route.query.modelKey
    },
    flowTitle(){
      return this.$route.query.flowTitle
    }
  },
  watch:{
    comment(val) {
      this.remark = val
    },
    actionName(val){
      this.approveLinkUserList = [];
      this.userSelectedList = [];
      this.actionMenuList.forEach(item=>{
        if(item.actionMenuName==val){
          this.approveLinkVar = item.actionmenuApprovaLinkVar;
          this.approvaLinkTitle = item.actionmenuApprovaLinkTitle;
          this.actionmenuApprovaLink = item.actionmenuApprovaLink;
          if(item.actionmenuApprovaLink=='true' && item.candidateUsers && item.candidateUsers.length>0){
            this.linkUserList = item.candidateUsers
          }
        }
      })
    },
  },
  created() {
    this.getActionMenu();
    this.getCommentList();
  },
  activated(){
    if(sessionStorage.getItem('approveLinkUserList')){
      this.approveLinkUserList = JSON.parse(sessionStorage.getItem('approveLinkUserList'));
    }
    if(sessionStorage.getItem('userSelectedList')){
      this.userSelectedList = JSON.parse(sessionStorage.getItem('userSelectedList'));
    }
  },
  methods: {
    onConfirm(value){
      this.comment = value.name;
      this.showPicker = false;
    },
    handleSelectUser(){
      sessionStorage.setItem('userlist',JSON.stringify(this.linkUserList))
      sessionStorage.setItem('approveLinkUserList',JSON.stringify(this.approveLinkUserList))
      sessionStorage.setItem('userSelectedList',JSON.stringify(this.userSelectedList))
      this.$router.push('/select/user');
    },
    // 查询审核操作菜单
    getActionMenu() {
      const params ={
        tenantId:this.tenantId,
        taskId:this.taskId,
        isApproveLink:false
      }
      this.loading = true;
      actionMenus(params).then(res => {
        if (res.code == 'SUCCESS:0000') {
          this.actionMenuList = res.data.actionMenus
          if(this.actionMenuList && this.actionMenuList.length>0){
            this.actionName = this.actionMenuList[0].actionMenuName
          }
        }
        this.loading = false;
      })
    },
    // 查询常用语列表
    getCommentList() {
      const params = {
        tenantId:this.tenantId
      }
      wordList(params).then(res => {
        if (res.code == 'SUCCESS:0000') {
          this.commentList = res.data;
        }
      })
    },
    submitApprove() {
      if (this.remark == '') {
        this.$toast({message:'请输入审批意见！',type:'warning'})
        return
      }
      const data = {
        actionName: this.actionName,
        approveLinkUserList: this.approveLinkUserList,
        approveLinkVar: this.approveLinkVar,
        comment: this.remark,
        processInstId: this.instanceId,
        taskId: this.taskId,
        tenantId: this.tenantId,
        serviceId: 'com.pingan.smartcity.rdp.swift.flow.engine.api.rest.inner.service.impl.InnerFormButtonServiceImpl'
      }
      approveSubmit(data).then(res => {
        if (res.code == 'SUCCESS:0000') {
          this.$toast({message:'操作成功！',type:'success'})
          this.$router.push('/todolist')
        }
      })
      .catch(() => {
        this.loading = false
      })
    },
  }
};
</script>
<style lang="scss" scoped>
::v-deep .van-tab__text--ellipsis {
  font-size: 0.16rem;
}
::v-deep .van-tab--active {
  color: #3a75c5;
}
::v-deep .van-field__control{
  padding-left: 0.11rem;
  font-size: 0.14rem;
  border-radius: 0.04rem;
  min-height: 0.3rem;
}
::v-deep .comment-input .van-field__control {
  border: 0.01rem solid #eeeeee;
  padding-top: 0.02rem;
}
::v-deep .van-cell {
  padding: 10px;
}
.main {
  background: #ffffff;
  margin-top: 0.09rem;
  .todo-title {
    padding: 0.12rem;
    color: #0b0b0b;
    font-size: 0.16rem;
    font-family: 微软雅黑
  }
  .approve-form {
    border: 0.01rem solid #eeeeee;
    padding-top: 0.1rem;
    padding-bottom: 0.19rem;
  }
  .tabs-select {
    padding-top: 0.14rem;
    padding-bottom: 0.19rem;
    .select-item {
      margin-top: 0.15rem;
      padding-left: 0.12rem;
      span {
        color: #7f7f7f;
        background: #f4f4f4;
        border-radius: 0.02rem;
        padding: 0.03rem 0.09rem;
        margin-right: 0.12rem;
        font-size: 0.12rem;
      }
    }
  }
}
.footer {
  position: absolute;
  padding: 0 0.12rem;
  bottom: -2.8rem;
  .footer-txt {
    color: #fff;
    background: #3a75c5;
    border-radius: 0.04rem;
    padding: 0.11rem 1.6rem;
  }
}
.fixed-bottom{
  position:fixed;bottom:0;width:100%;
}
.vanButton{
  width:100%;
  height:0.42rem;
  border-radius:0.03rem
}
::v-deep .van-cell__left-icon, .van-cell__right-icon {
    height: 0.38rem;
    font-size: 0.38rem;
}
</style>
