<template>
    <div>
        <div v-if="fileList.length==0" style="margin-top:0.15rem;text-align:center;color:gray">
            <span style="position:relative;">暂无附件</span> 
        </div>
        <div v-else style="padding-top:0.10rem;background:#ffffff">
            <div v-for="(item,index) in fileList" :key="index" style="display:flex;align-items:center;padding:0.09rem 0.12rem">
                <img :src="getFileIconByType(item)" height="33rem" width="32rem" alt="" />
                <a style="font-size:0.15rem;margin-left:12px;color:#0b0b0b;font-family:微软雅黑" @click="handleFileUrl(item)">{{item.name}}</a>
            </div>
        </div>
    </div>
</template>
<script>
import wordIcon from '@/assets/word.png'
import pdfIcon from '@/assets/pdf.png'
import excelIcon from '@/assets/excel.png'
import txtIcon from '@/assets/txt.png'
export default {
    props:{
        fileList:{
            type:Array,
            default:()=>{
                return []
            }
        }
    },
    data(){
        return{
            textFileUrl:'',
            wordIcon:wordIcon,
            pdfIcon:pdfIcon,
            excelIcon:excelIcon,
            txtIcon:txtIcon,
        }
    },
    mounted(){
        // this.fileList = this.fileList.concat({id:'123131313',name:'哈哈哈哈哈dadadadadadadadad哈哈的大大.pdf'})
    },
    methods:{
        handleFileUrl(item){
            this.$router.push('/textfile?fileId='+item.id+'&title='+item.name)
        },
        getFileIconByType(item){
            let fileIcon = '';
            let fileTypeArr = item.name.split('.');
            let fileType = fileTypeArr[fileTypeArr.length-1];
            if(fileType=='txt'){
                fileIcon = this.txtIcon; 
            }else if(fileType=='doc' || fileType=='docx'){
                fileIcon = this.wordIcon; ; 
            }else if(fileType=='pdf'){
                fileIcon = this.pdfIcon; ; 
            }else if(fileType=='xlsx' || fileType=='xls'){
                fileIcon = this.excelIcon; ; 
            }
            return fileIcon
        },
    }
    
}
</script>
